<template>
  <div class="main">
    <section class="module-small">
      <div class="container">
        <div class="row multi-columns-row">
          <product-card
            v-for="(product, index) in paginatedItems"
            :key="index"
            :product="product"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped src="@/css/Main_style.scss"></style>

<script>
import { mapState, mapGetters } from "vuex";
import ProductCard from "./t-shop-card.vue";
export default {
  data() {
    return {
      currentPage: 1,
      perPage: 12,
      paginatedItems: [],
      lang: "uz",
    };
  },
  components: {
    ProductCard,
  },
  methods: {
    paginate(page_size, page_number) {
      let itemsToParse = this.products;
      this.paginatedItems = itemsToParse.slice(
        page_number * page_size,
        (page_number + 1) * page_size
      );
    },
    onPageChanged(page) {
      this.paginate(this.perPage, page - 1);
    },
  },
  mounted() {
    this.paginate(this.perPage, 0);
  },
  computed: {
    ...mapState("product", ["products"]),
    ...mapGetters("product", ["productsItemCount"]),
  },
};
</script>