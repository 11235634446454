export const productsItem = (state) => {
    return state.products;
}

export const productsItemCount = (state) =>{
    return state.products.length;
}

export const getProductById = (state) => (id) => {
    return state.products.find(item => item.id === id);
}
export const getProductByRouterName = (state) => (name) => {
    return state.products.find(item => item.router_name === name)
}

export const getProducts = (state) => {
    return state.products;
}

export const getProductsByCategory = (state) => (category) =>{
    let senditems= [];
    console.log(category);
    state.products.forEach(item => {
        if(item.category === category)
        {
            senditems.push(item)
        }
 
    })
    // console.log(category)
    // items.push(state.products.find(item => item.category === category));

    return senditems;
}

export const getProductsByBosh = (state) => {
    let items = state.products.find(item => item.category === "bosh");
    return items;
}

