export const getProduct = ({commit,getters}, productId) =>{
    // const product = getters('getProductById', productId);
    const product = getters.getProductById(productId);
    commit('SET_PRODUCT', product);
}

export const addToProductState = ({commit}, product) => {
    commit('SET_PRODUCT', product);
}

