<template>
  <section class="faq" id="faq">
    <Title
    title_id="imgTitle-page-faq"
    :title_text="$t('page-title.faq.title')"
    :subtitle_text="$t('page-title.faq.subtitle')" />

    <Faq />
    <Footer />
  </section>
</template>

<script>
import Title from "../components/widgets/w-titleImagebar.vue";
import Faq from "../components/Titan/t-faq.vue";
import Footer from "../components/Titan/t-footer.vue";

export default {
  components: {
    Title,
    Faq,
    Footer,
  },
};
</script>