<template>
  <div class="main">
    <Title
      title_id="imgTitle-page-shop"
      :title_text="$t('page-title.shop.title')"
      :subtitle_text="$t('page-title.shop.subtitle')"
    />
    <tShop />
    <t-footer />
  </div>
</template>

<style lang="scss" scoped>
// .main {
//   margin-top: 90px;
//   @media (max-width: 1049px) {
//     margin-top: 74px;
//     // padding: 30px 0px;
//   }
// }
</style>

<script>
import Title from "../components/widgets/w-titleImagebar.vue";
import tShop from "../components/Titan/t-shop.vue"
import tFooter from "../components/Titan/t-footer.vue";

export default {
  components: {
    Title,
    tShop,
    tFooter,
  },
};
</script>