<template>
  <div class="contact1">

    <Title
      title_id="imgTitle-page-contact"
      :title_text="$t('page-title.contact.title')"
      :subtitle_text="$t('page-title.contact.subtitle')"
    />

    <t-contact />
    <t-footer />
  </div>
</template>

<script>
import Title from "../components/widgets/w-titleImagebar.vue";
import tContact from "../components/Titan/t-contact.vue";
import tFooter from "../components/Titan/t-footer.vue";

export default {
  components: {
    Title,
    tContact,
    tFooter,
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/variables.scss";
$color_bg_section: white;
</style>