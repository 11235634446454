<template>
  <div class="t-products">
    <section class="module-small">
      <b-row>
        <b-col>
          <h3 class="module-title font-alt">{{ this.selectedCategory }}</h3>
        </b-col>
        <!-- <b-col>
          <v-menu offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <el-button
                class="mr-3"
                circle
                v-bind="attrs"
                v-on="on"
                icon="el-icon-s-operation"
              >
              </el-button>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, i) in sortItems"
                :key="i"
                @click="callMethod(item.method)"
              >
                <v-list-item-icon class="mr-3">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 0.85rem;">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </b-col> -->
      </b-row>
      <b-container class="pl-5 pr-5">
        <b-row align-v="center" class="center">
          <b-col
            lg="3"
            md="6"
            class="pb-5"
            v-for="(product, index) in sortedProducts"
            :key="index"
          >
            <product-card :product="product" />
          </b-col>

          <!-- <product-card
          v-for="(product, index) in sortedProducts"
          :key="index"
          :product="product"
        /> -->
        </b-row>
      </b-container>
    </section>

    <!-- 3D TEST UCHUN  -->
    <!-- <div class="swiper-box">
      <swiper class="swiper" :options="swiperOption3d">
        <swiper-slide>
          <img
            src="@/images/test-3d/img-up.jpeg"
            alt="Image"
            class="img-dori"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            src="@/images/test-3d/img-left.jpeg"
            alt="Image"
            class="img-dori"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            src="@/images/test-3d/img-back.jpeg"
            alt="Image"
            class="img-dori"
          />
        </swiper-slide>
        <swiper-slide>
          <img
            src="@/images/test-3d/img-right.jpeg"
            alt="Image"
            class="img-dori"
          />
        </swiper-slide>
      </swiper>
    </div> -->
    <!-- 3D TEST UCHUN  -->
  </div>
</template>

<style lang="scss" scoped>
.bar {
  margin-left: 0;
  margin-right: 0;
}

.center {
  justify-content: center;
}
/* Module header */
.module-small {
  padding: 70px 0;
}
.module-title {
  position: relative;
  letter-spacing: 4px;
  text-align: center;
  font-weight: 400;
  color: #111;
  margin: 0 0 50px;
}
.module-subtitle {
  text-align: center;
  font-size: 16px;
  color: #111;
  z-index: 999;
}
.font-alt {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: black;
}
.font-serif {
  font-family: Volkhov, 'Times New Roman', sans-serif;
  font-style: italic;
}

.example-3d {
  position: relative;
  overflow: hidden;
  height: 360px;
  padding: 15px;
}

.img-dori {
  width: 200px;
}

.swiper-box {
  justify-content: center;
  align-items: center;
  padding-left: 50px;
}
.swiper {
  width: 200px !important;
  height: 350px;
  left: 50%;
  top: 50%;
  margin-left: -150px;
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
    // background-color: #2c8dfb;
    background-color: white;
    background-position: center;
    background-size: cover;
    color: white;
  }
  .swiper-pagination {
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: white;
    }
  }
}
.category {
  margin-bottom: 50px;
}
// CART STYLE
.card {
  margin: auto;
  max-width: 1260px;
  width: 100%;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  border-radius: 0.5rem;
  border: transparent;
}

@media (max-width: 767px) {
  .card {
    margin: 3vh auto;
  }
}

.align-center {
  text-align: center;
  font-size: 16px;
}

img {
  width: 3.5rem;
}

hr {
  margin-top: 1.25rem;
}

form {
  padding: 2vh 0;
}

.btn {
  background-color: #000;
  border-color: #000;
  color: white;
  width: 100%;
  font-size: 0.7rem;
  margin-top: 4vh;
  padding: 1vh;
  border-radius: 0;
}

.btn:focus {
  box-shadow: none;
  outline: none;
  box-shadow: none;
  color: white;
  -webkit-box-shadow: none;
  // -webkit-user-select: none;
  transition: none;
}

.btn:hover {
  color: white;
}

a {
  color: black;
}

a:hover {
  color: black;
  text-decoration: none;
}
// SIDEBAR
.hidden {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  z-index: 1;
  overflow-y: auto;
  letter-spacing: 1px;
  font-weight: 400px;
  font-style: normal;
}
.vs-sidebar-content {
  width: calc(100% - 15px);
}

.vs-sidebar__item {
  font-weight: normal;
}

.vs-sidebar__group {
  min-width: 100px;
}

@media (min-width: 560px) {
  .col-sm-offset-3 {
    margin-left: 25%;
  }
}
@media (max-width: 1200px) {
  /* Features */
  .alt-features-item {
    margin: 20px 0 0;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1360px;
  }
}

// Mobile version
@media (max-width: 1080px) {
  .category {
    max-width: 100%;
  }

  .items {
    max-width: 100%;
  }
  .bar {
    padding: 20px;
  }
}
</style>

<script>
import { mapGetters, mapState } from 'vuex'
import 'swiper/dist/css/swiper.css'
import ProductCard from './t-products-card.vue'
// import ProductCard from './t-products/productCardElement.vue'

export default {
  data () {
    return {
      active: false,
      swiperOption3d: {
        effect: 'cube',
        grabCursor: true,
        loop: true,
        cubeEffect: {
          shadow: true,
          slideShadows: true,
          shadowOffset: 20,
          shadowScale: 0.94
        },
        pagination: {
          el: '.swiper-pagination'
        }
      },

      selectedCategory: '',
      paginatedItems: [],
      sortedProducts: [],

      currentPage: 1,
      perPage: 8,

      lang: 'uz'
    }
  },
  components: {
    ProductCard
  },
  methods: {
    mouseOver: function () {
      this.active = false
    },

    getItems () {
      this.sortedProducts = this.products
      this.selectedCategory = this.$t('category')
    },
    sortByCategories (category) {
      let vm = this
      this.sortedProducts = []

      if (category === 'All') {
        this.sortedProducts = this.products
        this.selectedCategory = this.$t('category')
      } else {
        this.products.map(function (item) {
          if (item.category === category) {
            vm.sortedProducts.push(item)
          }
        })
        this.selectedCategory = category
      }
    },

    paginate (page_size, page_number) {
      let itemsToParse = this.products
      this.paginatedItems = itemsToParse.slice(
        page_number * page_size,
        (page_number + 1) * page_size
      )
    },
    onPageChanged (page) {
      this.paginate(this.perPage, page - 1)
    }
  },
  mounted () {
    //this.paginate(this.perPage, 0);
    this.getItems()
  },

  computed: {
    ...mapState('product', ['products']),
    ...mapGetters('product', ['productsItemCount'])
  }
}
</script>
