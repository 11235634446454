import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

import state from "./state";
import * as getters from './getters';
import * as mutations from "./mutations";
import * as actions from "./actions";

// import notification from './modules/notification';
import cart from './modules/cart';
import product from './modules/product';

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions,

    modules:{
      // notification,
      product,
      cart,
    },

    plugins: [
        // createPersistedState({storage: window.sessionStorage})
        createPersistedState({
          paths: ['product.product', 'cart']
        })
      ]
})