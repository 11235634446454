import Vue from 'vue'
import App from './App.vue'

// Bootstrap import file
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

import VueScrollReveal from 'vue-scroll-reveal'

// Font awesome import file
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

// Reveal AOS animation import file
import AOS from 'aos'
import 'aos/dist/aos.css'

import router from './router'

// Element UI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// For Submit message to Telegram Bot
import vueResource from 'vue-resource'

// Alert
import notification from 'vue-notification-ui'
// Vuex
import store from './store'

// MultiLanguage
import i18n from './i18n'

// Vuesax
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.use(Vuesax, {
  // options here
})

Vue.use(vueResource)

Vue.use(ElementUI)

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.config.productionTip = false

Vue.use(VueScrollReveal, {
  class: 'v-scroll-reveal', // A CSS class applied to elements with the v-scroll-reveal directive; useful for animation overrides.
  duration: 800,
  scale: 1,
  distance: '10px',
  mobile: false
})

// ALERT
Vue.use(notification, {
  position: 'notification-top-right', // top, bottom, left, right
  duration: 5000, // default
  left: 20, // default
  bottom: 20, // default
  top: 20, // default
  right: 40 // default
})

router.beforeEach((to, from, next) => {
  let language = to.params.lang
  if (!language) {
    language = 'en'
  }

  i18n.locale = language
  next()
})

new Vue({
  router,
  store,
  render: h => h(App),
  i18n,

  mounted () {
    AOS.init()
  }
}).$mount('#app')
