export const SET_PRODUCT = (state, data) =>{
    state.product = data;

    // state.product = state.products.find(item=>{
    //     return item.id === id;

    //     // if(item.id === id)
    //     //     return item;
    //     // else 
    //     //     return item
    // })
}