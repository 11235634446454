<template>
  <div>
    <div>
      <!-- Sample 1 -->
      <!-- <b-alert show :variant="typeClass" dismissible>
        {{ notification.message }}
      </b-alert> -->

      <!-- Sample 2 slowly animation -->
      <b-alert
        :show="dismissSecs"
        dismissible
        fade
        :variant="typeClass"
        @dismiss-count-down="countDownChanged"
      >
        {{ notification.message }}
      </b-alert>
    </div>
  </div>
</template>

<script>
export default {
  props: ["notification"],
  data() {
    return {
      timeout: null,
      dismissSecs: 5,
      showDismissibleAlert: false,
    };
  },
  computed: {
    typeClass() {
      return `${this.notification.type}`;
    },
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>
