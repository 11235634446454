<template>
  <div class="lang-switch">
    <button
      href="#"
      class="lang-button"
      @click="toggleVisibility"
      @keydown.space.exact.prevent="toggleVisibility"
      @keydown.esc.exact="hideDropdown"
      @keydown.shift.tab="hideDropdown"
      @keydown.up.exact.prevent="startArrowKeys"
      @keydown.down.exact.prevent="startArrowKeys"
    >
      <img
        v-bind:src="
          require('../..//images/for-languages/flag_' +
            $i18n.locale +
            '_medium.png')
        "
        alt="flag"
        class="icon"
      />
    </button>

    <transition name="dropdown-fade">
      <ul
        v-on-clickaway="hideDropdown"
        v-if="isVisible"
        ref="dropdown"
        class="dropdown-trans"
      >
        <li>
          <a
            href="#"
            @click.prevent="setLocale('uz')"
            ref="account"
            class="dropdown-trans-a"
            @keydown.up.exact.prevent=""
            @keydown.tab.exact="focusNext(false)"
            @keydown.down.exact.prevent="focusNext(true)"
            @keydown.esc.exact="hideDropdown"
          >
            <img
              src="../../images/for-languages/flag_uz_medium.png"
              alt="english flag"
              class="inner-icon"
            />
            <span class="ml-2">O'zbekcha</span>
          </a>
        </li>

        <li>
          <a
            href="#"
            @click.prevent="setLocale('ru')"
            class="dropdown-trans-a"
            @keydown.shift.tab="focusPrevious(false)"
            @keydown.up.exact.prevent="focusPrevious(true)"
            @keydown.down.exact.prevent=""
            @keydown.tab.exact="hideDropdown"
            @keydown.esc.exact="hideDropdown"
          >
            <img
              src="../../images/for-languages/flag_ru_medium.png"
              alt="english flag"
              class="inner-icon"
            />
            <span class="ml-2">Русский</span>
          </a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
export default {
  mixins: [clickaway],
  data() {
    return {
      isVisible: false,
      focusedIndex: 0,
    };
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
    hideDropdown() {
      this.isVisible = false;
      this.focusedIndex = 0;
    },
    startArrowKeys() {
      if (this.isVisible) {
        this.$refs.dropdown.children[0].children[0].focus();
      }
    },
    focusPrevious(isArrowKey) {
      this.focusedIndex = this.focusedIndex - 1;
      if (isArrowKey) {
        this.focusItem();
      }
    },
    focusNext(isArrowKey) {
      this.focusedIndex = this.focusedIndex + 1;
      if (isArrowKey) {
        this.focusItem();
      }
    },
    focusItem() {
      this.$refs.dropdown.children[this.focusedIndex].children[0].focus();
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.$router.push({
        params: { lang: locale },
      });
      this.hideDropdown();
    },
  },
};
</script>

<style lang='scss' scoped>
.dropdown-fade-enter-active,
.dropdown-fade-leave-active {
  transition: all 0.1s ease-in-out;
  position: fixed;
}
.dropdown-fade-enter,
.dropdown-fade-leave-to {
  opacity: 0;
  transform: translateY(-12px);
}

.lang-switch {
  position: relative;
  font-family: "Catamaran", sans-serif;
  font-size: 13px;
  padding: 0;
  margin: 0;
  align-items: center;
}
.lang-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  justify-content: center;
}
.dropdown-trans {
  position: fixed;
  z-index: 990;
  right: 0;
  margin-right: 50px;
  font-style: normal;
  background-color: white;
  overflow: hidden;
  box-shadow: 1pc;
  text-decoration: none;
  border-radius: 10px;
  width: 150px;
  list-style-type: none;
  padding: 0;
  border: 0.3px solid #bdb9b98e;

}

.dropdown-trans-a {
  display: flex;
  align-items: center;
  padding: 3px 3px;
  color: #2a2f41;
}
.icon {
  width: 29px;
  height: 29px;
  margin-top: 3px;
  visibility: visible;
}

.inner-icon {
  width: 28px;
  height: 28px;
  margin-left: 0px;
  margin-top: 0px;
  visibility: visible;
}

.dropdown-trans a {
  padding: 3px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-trans a:hover {
  background-color: rgba(128, 128, 128, 0.397);
}

.dropdown:hover .dropdown-content {
  display: block;
}

// MOBILE VERSION
@media (max-width: 1049px) {
  .lang-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
</style>