<template>
  <section class="about" id="about">
    <Title
      title_id="imgTitle-page-about"
      :title_text="$t('page-title.about.title')"
      :subtitle_text="$t('page-title.about.subtitle')"
    />
    <About />
    <Footer />
  </section>
</template>

<script>
import Title from "../components/widgets/w-titleImagebar.vue";
import About from "../components/Titan/t-about.vue";
import Footer from "../components/Titan/t-footer.vue";

export default {
  components: {
    Title,
    About,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/variables.scss";
$color_bg_section: white;
</style>