<template>
  <div>
    <el-card :body-style="{ padding: '0px' }">
      <img :src="require('@/assets' + product.image)" class="image" />
      <div style="padding: 14px;">
        <span> {{ product.name[selectLanguage()] }} </span>

        <el-tag
          effect="light"
          size="small"
          v-if="product.type"
          :type="typeClass"
          >{{ product.type[selectLanguage()] }}</el-tag
        >

        <div class="bottom clearfix">
          <span class="time"> {{ product.title[selectLanguage()] }}</span>
        </div>

        <div class="bottom-buttons text-right">
          <el-tooltip :content="product.cost + ' sum'" placement="top">
            <el-button
              icon="el-icon-shopping-cart-2"
              class=""
              @click="addToCart()"
              circle
            ></el-button>
          </el-tooltip>
          <!-- <router-link
            :to="{
              name: 'singleproduct',
              params: {
                id: product.id,
                name: product.name[selectLanguage()]
              }
            }"
          > -->
          <el-button class="" @click="goProduct()"> {{ $t('more') }}</el-button>
          <!-- </router-link> -->
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: ['product'],
  data () {
    return {
      currentDate: new Date(),
      lang: 0
    }
  },
  computed: {
    typeClass () {
      let product_type = 0
      if (this.product.type_index === 0) {
        product_type = 'success'
      } else if (this.product.type_index === 1) {
        product_type = 'warning'
      } else {
        product_type = ''
      }

      return product_type
    }
  },
  methods: {
    ...mapActions('cart', ['addProductToCart']),
    ...mapActions('product', ['addToProductState']),

    addToState () {
      this.addToProductState(this.product)
    },
    addToCart () {
      this.addProductToCart({
        quantity: 1,
        product: this.product,
        lang: 0
      })
    },
    selectLanguage () {
      // console.log(this.$i18n.locale);
      if (this.$i18n.locale === 'uz') {
        this.lang = 0
        return 0
      } else if (this.$i18n.locale === 'ru') {
        this.lang = 1
        return 1
      } else if (this.$i18n.locale === 'en') {
        this.lang = 2
        return 2
      }
    },
    goProduct () {
      this.addToProductState(this.product)
      // console.log(this.product.id)
      // console.log(this.product.name)
      this.$router.push({
        name: 'singleproduct',
        params: {
          router_name: this.product.router_name,
          // name: this.product.name[this.selectLanguage()]
        }
      })
    }
  }
}
</script>

<style>
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 15px;
  line-height: 12px;
}

.bottom-buttons {
  padding-top: 15px;
  margin-bottom: 5px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}

.clearfix:after {
  clear: both;
}
</style>
