<template>
  <div class="main">
    <Title
      title_id="imgTitle-page-products"
      :title_text="$t('page-title.product.title')"
      :subtitle_text="$t('page-title.product.subtitle')"
    />
    <Products />
    <Footer />
  </div>
</template>


<script>
import Title from "../components/widgets/w-titleImagebar.vue";
import Products from "../components/Titan/t-products.vue";
import Footer from "../components/Titan/t-footer.vue";


export default {
  components: {
    Title,
    Products,
    Footer,
  },
};
</script>
