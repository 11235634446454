export const ADD_TO_CART = (state, {quantity, product}) =>{ 
    let productInCart = state.cart.find(item => {
        return item.product.id === product.id;
    })

    if(productInCart){
        console.log(quantity)
        productInCart.quantity += quantity;

        return;
    }

    state.cart.push({
        quantity,
        product, 
    })
}

export const INCREMENT = (state, index) =>{
    state.cart[index].quantity++;
}

export const DECREMENT = (state, index) => {
    if(state.cart[index].quantity > 1) {
        state.cart[index].quantity--;
    }
}

export const REMOVE_FROM_CART = (state, index) => {
    state.cart.splice(index, 1)
}

export const REMOVE_FROM_CART_ALL = (state) => {
    state.cart = []
}