import axios from 'axios'
// import Product from "../../../apis/Products"

//export const getProducts = ({ commit }) => {
    // axios.get("http://localhost:3001/products")
    // Product.all()
        // .then(response => {
        //     commit('SET_PRODUCTS', response.data)
        // })
//}

//export const getProduct = ({ commit }, productId) => {
    // axios.get(`http://localhost:3001/products/${productId}`)
    // Product.show(productId)
    //     .then(response => {
    //         commit('SET_PRODUCT', response.data);
    //     })
//}

// export const getProduct = ({commit, getters}, productId) =>{
//     // const product = getters('getProductById', productId);
//     const product = getters.getProductById(productId);
//     console.log("action: " + product);
//     commit('SET_PRODUCT', product);
// }


// contact page - send message to telegram

export const sendMessage = ({dispatch}, fullMessage) =>{
    const token= "6504644931:AAHB1qPIuMl9xCJNOy0FdraYTJOT86CcQ3g";
    // const chatId = 115242787;
    const chatId = 320968728;

    axios.post(`https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=${fullMessage}`)
    .then(
        (response) => {
          console.log("Succsessfully!", response);

          dispatch('addNotification', {
            type: 'success',            
            message: 'Message sended successfuly.'
            },{root: true})
        },
        (error) => {
          console.log(error);
          alert("Error sending message!");

          dispatch('addNotification', {
            type: 'error',
            message: 'Error sending message!!!.'
        },{root: true})
        }
      );
}

// notification
export const addNotification = ({commit}, notification) => {
    commit('PUSH_NOTIFICATION', notification)
}