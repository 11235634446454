export const cartTotalPrice = (state) =>{
    let total = 0;

    state.cart.forEach(item => {
        if(item.product.cost !== '-'){
            let add1 = item.product.cost.replace(',','.')
            // total += item.product.cost * item.quantity;
            total += parseFloat(add1) * item.quantity;
        }

    })

    return total.toFixed(2);
}

export const cartTotalProductCount = (state) =>{
    let total = 0;

    state.cart.forEach(item => {
        total += item.quantity;
    })

    return total;
}