<template>
  <div class="t-faq">
    <section class="module">
      <div class="container">
        <!-- SEARCH -->
        <div class="row mb-60">
          <div class="col-sm-8 col-sm-offset-2">
            <form role="form">
              <div class="search-box">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Search..."
                />
                <button class="search-btn" type="submit">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
        <!-- TAB PANEL MENU -->
        <div class="row">
          <div class="col-sm-8 col-sm-offset-2">
            <div role="tabpanel">
              <div class="tab-content">
                <div
                  class="tab-pane"
                  :class="{ 'active show': isActive('support') }"
                  id="support"
                >
                  <h3 class="module-title font-alt">
                    {{ $t("page-faq.empty-title") }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped src="@/css/Main_style.scss"></style>

<script>
export default {
  data() {
    return {
      activeItem: "support",
    };
  },
  methods: {
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
  },
};
</script>