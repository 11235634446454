import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Page_Main.vue";
import Products from "../views/Page_Products.vue";
import About from "../views/Page_About.vue";
import Contact from "../views/Page_Contact.vue";
import Faq from "../views/Page_Faq.vue";
import Shop from "../views/Page_Shop.vue";
import SingleProduct from "../views/Page_Single_Product.vue";
import Language from "../views/Page_Language.vue";
import Cart from "../views/Page_Cart.vue";
import i18n from "../i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '/:lang',
    component:{
      // template: "<router-view></router-view>"
      render (c) {return c('router-view')}
    },
    children: [
    {
      path: '/lang',
      name: 'Language',
      meta: { title: 'language' },
      component: Language,
    },
    {
      path: '/',
      name: 'Home',
      meta: { title: 'home' },
      component: Home,
    },
    // {
    //   path: '/home',
    //   name: 'Home',
    //   meta: { title: 'home' },
    //   component: Home,
    // },
    {
      path: 'products',
      name: 'Products',
      meta: { title: 'product' },
      component: Products,
    },
    {
      path: 'about',
      name: 'About',
      meta: { title: 'about' },
      component: About,
    },
    {
      path: 'contact',
      name: 'Contact',
      meta: { title: 'contact' },
      component: Contact,
    },
    {
      path: 'faq',
      name: 'FAQ',
      meta: { title: 'faq' },
      component: Faq,
    },
    {
      path: 'shop',
      name: 'Shop',
      meta: { title: 'shop' },
      component: Shop,
    },
    {
      path: 'singleproduct',
      name: 'Single Product',
      meta: { title: 'shop' },
      component: SingleProduct,
      props: true
    },
    {
      path: 'products/:router_name',
      component: SingleProduct,
      meta: {title: 'shop'},
      name: 'singleproduct',
      props: true
    },
    {
      path: 'cart',
      name: 'Cart',
      meta: { title: 'cart' },
      component: Cart,
    }
  ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
