<template>
  <div class="page-language">
    <Language />
  </div>
</template>

<style lang="scss" scoped>
.page-language {
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  // padding-top: 110px;
  background: #051a20;

  // @media (max-width: 1049px) {
  //   padding-top: 100px;
  // }
}
</style>

<script>
import Language from "../components/Titan/t-language.vue";

export default {
  components: {
    Language,
  },
};
</script>