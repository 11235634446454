<template>
  <section class="cart" id="cart">
    <!-- <Title :title_text="$t('page-title.cart.title')" class="title" /> -->
    <Title 
    title_id="imgTitle-page-cart"
    :title_text="$t('page-title.cart.title')" 
    :subtitle_text="$t('page-title.cart.subtitle')" />
    <Cart />
    <Footer />
  </section>
</template>

<style lang="scss" scoped>
// .cart {
//   margin-top: 90px;
//   @media (max-width: 1049px) {
//     margin-top: 74px;
//   }
// }
</style>


<script>
import Title from "../components/widgets/w-titleImagebar.vue"
import Cart from "../components/Titan/t-cart.vue";
import Footer from "../components/Titan/t-footer.vue";

export default {
  components: {
    Title,
    Cart,
    Footer,
  },
};
</script>