<template>
  <div class="notifications-list">
    <NotificationMessage
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
    />
  </div>
</template>

<script>
import NotificationMessage from "./t-notification-message.vue";
import { mapState } from "vuex";
export default {
  components: {
    NotificationMessage,
  },
  computed: {
    ...mapState(["notifications"]),
  },
};
</script>

<style>
.notifications-list {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 15px;
  width: 320px;
  z-index: 999;
}
</style>